<template>
    <div class="main">
        <div class="search">

        </div>
        <div class="data">
            <div style="width: 100%;border: 1px solid #cfcfd1;border-radius: 5px;padding: 20px;background-color: #fff9ff">
                <el-collapse v-model="activeNames" @change="handleChange" accordion>
                    <el-collapse-item :key="index" v-for="(rank,index) in rankList"
                                      :title="'▷ '+rank.rankName + ' ('+rank.rankUserCount+'명)'"
                                      :name="index">
                        <div style="padding: 30px 10px;">
                            <div style="width: 100%">
                                <table class="table100">
                                    <tr>
                                        <td colspan="2" style="text-align: left">
                                            <el-upload
                                                    ref="upload"
                                                    class="upload-demo"
                                                    action="#"
                                                    :http-request="httpRequest"
                                                    :before-upload="beforeUpload"
                                                    :on-change="fileChanged"
                                                    :file-list="fileList"
                                                    :show-file-list="false"
                                            >
                                                <el-button slot="trigger" size="small" type="primary">이미지선택
                                                </el-button>
                                                <el-button
                                                        style="margin-left: 10px"
                                                        size="small"
                                                        type="success"
                                                        @click="submitUpload(rank)"
                                                >이미지등록
                                                </el-button>

                                            </el-upload>
                                        </td>
                                        <td colspan="5" style="text-align: right">
                                            <el-button size="mini" type="primary"
                                                       @click="updateRank(rank)">
                                                {{rank.rankName}} 업데이트
                                            </el-button>
                                            <el-button size="mini" type="warning" @click="copyRank(rank)">
                                                {{rank.rankName}} 복사
                                            </el-button>
                                            <el-button size="mini" type="danger" @click="deleteRank(rank)">
                                                {{rank.rankName}} 삭제
                                            </el-button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div style="width:100%;max-height: 450px;overflow-y: scroll;">
                                <!--레벨설정-->
                                <table class="table100">
                                    <tr>
                                        <th style="width: 13%">이미지</th>
                                        <th style="width: 13%">레벨명</th>
                                        <th style="width: 13%">상태</th>
                                        <th style="width: 13%">초기값</th>
                                        <th style="width: 13%">정열순위</th>
                                        <th>로그인지급회수</th>
                                        <th>로그인 포인트</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img :src="rank.imageUrlTemp"
                                                 style="width: 28px;height:28px;vertical-align: middle">
                                        </td>
                                        <td>
                                            <el-input v-model="rank.rankName" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-select size="mini" style="width: 90%" v-model="rank.status"
                                                       placeholder="상태">
                                                <el-option :value="managerConst.ENABLE" label="정상">정상
                                                </el-option>
                                                <el-option :value="managerConst.DISABLE"
                                                           label="사용정지">사용정지
                                                </el-option>
                                            </el-select>
                                        </td>
                                        <td>
                                            <el-select size="mini" style="width: 90%" v-model="rank.initial"
                                                       placeholder="예">
                                                <el-option :value="managerConst.ENABLE" label="예">예</el-option>
                                                <el-option :value="managerConst.DISABLE"
                                                           label="아니오">아니오
                                                </el-option>
                                            </el-select>

                                        </td>
                                        <td>
                                            <el-select size="mini" style="width: 90%" v-model="rank.sort"
                                                       placeholder="정열">
                                                <el-option :value="sort" :label="sort" :key="sort"
                                                           v-for="sort in sortArray"></el-option>
                                            </el-select>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.loginfrequency" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td >
                                            <el-input v-model="rank.loginpoint" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>첫충(%)(스포츠,미니,카지노,슬롯)</th>
                                        <th style="width: 13%">첫충최대치</th>
                                        <th style="width: 13%">첫충-추천인%</th>
                                        <th style="width: 13%">매충(%)(스포츠,미니,카지노,슬롯)</th>
                                        <th style="width: 13%">단폴베팅배당(-)</th>
                                        <th style="width: 13%">게시글P지급회수</th>
                                        <th style="width: 13%">게시글포인트</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <el-input v-model="rank.rechargebonus1" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>

                                        <td>
                                            <el-input v-model="rank.rechargeBonusMaxLimit" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.firstrecharge2frerrerpercent" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.dayBonusPercent" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betOneWincashPercent" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                        <td>
                                            <el-input v-model="rank.bbsPointsFrequency" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>

                                        <td>
                                            <el-input v-model="rank.bbsPoints" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>


                                    </tr>
                                    <tr>
                                        <th>당일페이백%</th>
                                        <th>당일페이백최대치</th>
                                        <th>주간페이백%</th>
                                        <th>주간페이백최대치</th>
                                        <th>1회최대환전금액</th>
                                        <th>1일최대환전금액</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <el-input v-model="rank.payback" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.paybackmax" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>

                                        <td>
                                            <el-input v-model="rank.paybackreferrer" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.paybackreferrermax" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.exchangeoncemaxamount" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                        <td>
                                            <el-input v-model="rank.exchangedaymaxamount" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>

                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="width: 13%">스포츠 배팅취소회수</th>
                                        <th style="width: 13%">스포츠 중복베팅회수</th>
                                        <th>스포츠 낙첨 지급포인트(%)</th>
                                        <th>스포츠 낙첨 `추천인`포인트(%)</th>
                                        <th>스포츠 `베팅` `추천인` 포인트(%)</th>
                                        <th>미니게임 베팅 지급포인트</th>
                                        <th></th>

                                    </tr>
                                    <tr>

                                        <td>
                                            <el-input v-model="rank.betCancel1day" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.repeatBetFrequency" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betLoseGivebackPercent" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betLoseGivebackReferrerPercent" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betGivebackReferrerPercent" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betGivebackPercent" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                        <td></td>
                                    </tr>
                                    <!--풀타임 스포츠-->
                                    <tr>
                                        <th>스포츠 최소베팅금액</th>
                                        <th>스포츠 풀 최대폴더수</th>
                                        <th>스포츠 풀 최대베당</th>
                                        <th>스포츠 풀 최대 `베팅`금액</th>
                                        <th>스포츠 풀 최대 `당첨`금액</th>
                                        <th>스포츠 단폴 최고베팅금액</th>
                                        <th>스포츠 두폴 최고베팅금액</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <el-input v-model="rank.betMinLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betFolderMaxLimit" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.oddsMaxLimit" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.winMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betOneMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.betTwoMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                    </tr>
                                    <!--스페셜 스포츠-->
                                    <tr>
                                        <th></th>
                                        <th>스페셜 최대폴더수</th>
                                        <th>스페셜 최대베당</th>
                                        <th>스페셜 최대 `베팅`금액</th>
                                        <th>스페셜 최대 `당첨`금액</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i class="fa fa-arrow-up"></i>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.specBetFolderMaxLimit" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.specOddsMaxLimit" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.specBetMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.specWinMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <i class="fa fa-arrow-up"></i>
                                        </td>
                                        <td>
                                            <i class="fa fa-arrow-up"></i>
                                        </td>
                                    </tr>

                                    <!--인플레이 -->
                                    <tr>
                                        <th>인플레이 최소베팅금액</th>
                                        <th>인플레이 최대폴더수</th>
                                        <th>인플레이 최대베당</th>
                                        <th>인플레이 최대 `베팅`금액</th>
                                        <th>인플레이 최대 `당첨`금액</th>
                                        <th>인플레이 단폴 최고베팅금액</th>
                                        <th>인플레이 두폴 최고베팅금액</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <el-input v-model="rank.inplayBetMinLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.inplayBetFolderMaxLimit" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.inplayOddsMaxLimit" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.inplayBetMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.inplayWinMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.inplayBetOneMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                        <td>
                                            <el-input v-model="rank.inplayBetTwoMaxLimitCash" size="mini"
                                                      style="width: 90%"></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>스포츠 요율%</th>
                                        <th>인플레이 요율%</th>
                                        <th>미니게임 요율%</th>
                                        <th>카지노 요율%</th>
                                        <th>슬롯 요율%</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <el-input v-model="rank.sportsrollingpercent" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                        <td>
                                            <el-input v-model="rank.inplayrollingpercent" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                        <td>
                                            <el-input v-model="rank.leisurerollingpercent" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                        <td>
                                            <el-input v-model="rank.casinorollingpercent" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                        <td>
                                            <el-input v-model="rank.slotrollingpercent" size="mini"
                                                      style="width: 90%"></el-input>

                                        </td>
                                    </tr>
                                </table>
                              <div class="badge badge-success"
                                   style="margin-top: 10px;width: 100%;padding: 10px 0">카지노사 스킨설정
                              </div>
                              <el-collapse v-model="activeName2" accordion style="margin-bottom: 10px">
                                <h3 style="color: red">스킨적용이 필요없을경우 숫자 0으로 설정해주세요</h3>

                                <table class="table100">
                                  <tr>
                                    <th style="width: 25%">Evolution</th>
                                    <th style="width: 25%">Dram </th>
                                    <th style="width: 25%">Asia</th>
                                    <th style="width: 25%">Pragmatic Live</th>
                                  </tr>
                                  <tr>
                                    <td>  <el-input v-model="rank.casinoskinevolutionlive" size="mini"
                                                    style="width: 90%"></el-input></td>
                                    <td>  <el-input v-model="rank.casinoskindreamlive" size="mini"
                                                    style="width: 90%"></el-input></td>
                                    <td>  <el-input v-model="rank.casinoskinasialive" size="mini"
                                                    style="width: 90%"></el-input></td>
                                    <td>  <el-input v-model="rank.casinoskinpragmaticlive" size="mini"
                                                    style="width: 90%"></el-input></td>

                                  </tr>
                                  <tr>
                                    <th style="width: 25%">Bota</th>
                                    <th style="width: 25%">MicroGame</th>
                                    <th style="width: 25%">Skywind</th>
                                    <th style="width: 25%">Sexy</th>
                                  </tr>
                                  <tr>
                                    <td>  <el-input v-model="rank.casinoskinbotalive" size="mini"
                                                    style="width: 90%"></el-input></td>
                                    <td>  <el-input v-model="rank.casinoskinmicrolive" size="mini"
                                                    style="width: 90%"></el-input></td>
                                    <td>  <el-input v-model="rank.casinoskinskywindlive" size="mini"
                                                    style="width: 90%"></el-input></td>
                                    <td>  <el-input v-model="rank.casinoskinsexylive" size="mini"
                                                    style="width: 90%"></el-input></td>
                                  </tr>
                                </table>
                              </el-collapse>
                                <!--레벨별 이벤트설정-->
                                <div class="badge badge-primary"
                                     style="margin-top: 10px;width: 100%;padding: 10px 0">이벤트 설정
                                </div>
                                <el-collapse v-model="activeName2" accordion style="margin-bottom: 50px">
                                    <el-collapse-item key="a1"
                                                      title="[클릭하세요] 출석이벤트 (출석및충전 이벤트;매달 첫날부터 마지막 날자 기준, ❄주의:총입금0입력시 출석하면 포인트 지급;포인트 0 입력시 포인트지급 안함❄)"
                                                      name="a1">
                                        <table class="table100">
                                            <tr>
                                                <th style="width: 13%">사용여부</th>
                                                <th style="width: 13%">일입금(출첵기준 동시적용)</th>
<!--                                                <th style="width: 13%">3일출석</th>-->
                                                <th style="width: 13%">7일출석</th>
                                                <th style="width: 13%">15일출석</th>
                                                <th style="width: 13%">25일출석</th>
<!--                                                <th style="width: 13%">28일출석</th>-->
                                            </tr>
                                            <tr>
                                                <td>
                                                    <el-select size="mini" style="width: 120px"
                                                               v-model="rank.loginEventUse"
                                                               placeholder="이벤트 사용여부">
                                                        <el-option :value="managerConst.ENABLE" label="진행">진행
                                                        </el-option>
                                                        <el-option :value="managerConst.DISABLE" label="정지">정지
                                                        </el-option>
                                                    </el-select>
                                                </td>
                                                <td>
                                                    <el-input v-model="rank.culcheckrechargeamount" size="mini"
                                                              style="width: 90%"></el-input>
                                                </td>
<!--                                                <td>-->
<!--                                                    <el-input v-model="rank.loginEvent3" size="mini"-->
<!--                                                              style="width: 90%"></el-input>-->
<!--                                                </td>-->
                                                <td>
                                                    <el-input v-model="rank.loginEvent7" size="mini"
                                                              style="width: 90%"></el-input>
                                                </td>
                                                <td>
                                                    <el-input v-model="rank.loginEvent14" size="mini"
                                                              style="width: 90%"></el-input>
                                                </td>
                                                <td>
                                                    <el-input v-model="rank.loginEvent21" size="mini"
                                                              style="width: 90%"></el-input>
                                                </td>
<!--                                                <td>-->
<!--                                                    <el-input v-model="rank.loginEvent28" size="mini"-->
<!--                                                              style="width: 90%"></el-input>-->
<!--                                                </td>-->
                                            </tr>
                                        </table>
                                    </el-collapse-item>
                                </el-collapse>

                                <!--레벨별 미니게임 베팅금액설정-->
                                <div class="badge badge-primary"
                                     style="margin-top: 10px;width: 100%;padding: 10px 0">미니게임 베팅금액 설정
                                </div>
                                <el-table
                                        :data="rank.leisureBetByRankConfigList"
                                        style="width: 100%;padding-bottom: 30px"
                                        border>
                                    <el-table-column
                                            fixed
                                            label="미니게임"
                                            width="300">
                                        <template slot-scope="scope">
                                            <span>{{scope.row.kindName}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            fixed
                                            label="최소베팅금액"
                                            width="200">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.minAmount" size="mini"
                                                      style="width: 90%"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            fixed
                                            label="최대베팅금액"
                                            width="200">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.maxAmount" size="mini"
                                                      style="width: 90%"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            fixed
                                            label="최대당첨금액"
                                            width="200">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.maxWin" size="mini"
                                                      style="width: 90%"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            fixed
                                            label="-"
                                            width="100">
                                        <template slot-scope="scope">
                                            <el-button size="mini" type="primary"
                                                       @click="updateLeisureBetConfig(scope.row)">업데이트
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>


                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>

        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {copyGroup, deleteGroup, getGroupList, updateGroup} from "../../network/manager/groupRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";
    import {
        copyRank,
        deleteRank,
        getRankList,
        updateLesureBetConfig,
        updateRank
    } from "../../network/manager/rankRequest";
    import {uploadFile} from "../../network/manager/commonRequest";

    export default {
        name: "ManagerManagerRank",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                activeNames: 0,
                activeName2: 'a1',
                rankList: [],
                sortArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                imageUrl: '',
                actionUrl: '/upload_image_3',
                fileList: [],
                file:null,
            }
        },
        methods: {
            getRankList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getRankList({}, 1, 100).then(res => {
                    this.rankList = res.data.data
                    this.rankList.map(r => {
                        r.imageUrlTemp = r.imageUrl
                        r.imageUrl = null
                    })
                    loadingInstance.close()
                })
            },
            updateRank(item) {
                this.$confirm('[' + item.rankName + '] 을 업데이트 하시겠습니까?' +
                    '*주의* 상태를 사용금지로 변경후 해당레벨에 회원은 전부 초기설정 레벨로로 변경되며 복구불가 합니다.', '레벨 업데이트', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    updateRank(item).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                            this.getRankList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 2000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                });
            },
            copyRank(item) {
                this.$confirm('[' + item.rankName + '] 을 복사 하시겠습니까?', '레벨 복사', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    copyRank(item.id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '복사 완료되였습니다'
                            });
                            this.getRankList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 2000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                });
            },
            deleteRank(item) {
                this.$confirm('[' + item.rankName + '] 을 삭제 하시겠습니까?' +
                    '해당렙에 속하는 회원의 레벨은 초기 레벨으로 셋팅됩니다.', '레벨 삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    deleteRank(item.id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getRankList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 2000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                });
            },
            updateLeisureBetConfig(item) {
                updateLesureBetConfig(item).then(res => {
                    this.$message({
                        type: 'success',
                        duration: 1000,
                        message: '미니게임 베팅금액 설정이 완료되였습니다'
                    });
                    this.getRankList()
                })
            },
            handleChange(val) {
                //console.log(val);
            },
            async submitUpload(rank) {
                if (this.imgChange) {
                    // 如果上传的文件改变了，执行文件上传方法获取新的地址
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    uploadFile(this.actionUrl, this.file).then(res=>{
                        if(res.data.success){
                            let uploadedFileName = res.data.data.uploadedFileName;
                            let fullpath = res.data.data.fullpath;
                            rank.imageUrl = uploadedFileName
                            updateRank(rank).then(res=>{
                                loadingInstance.close()
                                this.getRankList()
                            })
                        }else {
                            loadingInstance.close()
                        }
                    })
                }
                // 执行新增操作 / 修改操作
            },
            // 覆盖默认行为
            httpRequest() {
                this.imgChange = true;
            },
            fileChanged(file, fileList) {
                this.file = file.raw;
            },
            // 上传之前
            beforeUpload(file) {

            },
        },
        created() {
            this.getRankList()
        },
        watch: {}
    }
</script>

<style scoped>

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 18px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }

    .avatar {
        width: 120px;
        height: 120px;
        display: block;
    }

</style>